export default {
    tableFields: [
      {
        name: '__component:badge-column',
        title: '',
        dataClass: 'text-center',
       // width: '4%'
      },
      {
        name: 'staff.code',
        title: 'Staff',
        sortField: 'staff.code',
        //width: '34%'
      } ,  
    /*  {
        name: 'device.name',
        title: 'Dispositivo',
        sortField: 'device.name',
        //width: '34%'
      },*/
     
      {
        name: 'amount',
        title: 'Monto',
        sortField: 'cant',
      //  width: '34%'
      },
     
      {
        name:       '__slot:status',
        title:      'Estatus',
        titleClass: "center aligned",
        dataClass:  "center aligned",       
    },
    {
        name: 'timestamp_formated',
        title: 'Fecha',
        sortField: 'timestamp_formated',
        //width: '34%'
      }  
     
   
  
    ],
    sortFunctions: {
      'name': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      },
      'location': function (item1, item2) {
        return item1 >= item2 ? 1 : -1
      }
    }
  }
  